import useAxios from 'axiosInstance';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { PRODUCT_TYPE, Purchase } from 'types/types';

export function usePurchasedeal(): UseMutateFunction<string, unknown, Purchase, unknown> {
  // const {user} = useUser()
  const { request } = useAxios();
  const queryClient = useQueryClient();

  async function submitDeal(dealId, price, priceUnit): Promise<string> {
    const data = {
      product_type: PRODUCT_TYPE.OFFERS,
      product_id: dealId,
      price: price,
      price_unit: priceUnit,
      success_url: `${process.env.REACT_APP_BASE_URL}/success`,
      cancel_url: `${process.env.REACT_APP_BASE_URL}/cancel`,
    };
    const res = await request({
      url: 'public/checkout',
      method: 'post',
      data: data,
    });
    return res.data.checkout_session_url;
  }

  const { mutate: purchaseDeal } = useMutation(
    (purchase: Purchase) => submitDeal(purchase.dealId, purchase.price, purchase.priceUnit),
    {
      onSuccess: (redirectUrl: string) => {
        queryClient.invalidateQueries([queryKeys.deals]);
        // toast.success('You have successfully made a purchase!')
        window.open(redirectUrl, '_self');
      },
    },
  );

  return purchaseDeal;
}
