import { CssBaseline, Stack, ThemeProvider } from '@mui/material'
import '@stripe/stripe-js'
import { ToastContainer } from 'material-react-toastify'
import 'material-react-toastify/dist/ReactToastify.css'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from '../../react-query/queryClient'
import MyRoutes from '../../routes'
import { dashboardTheme } from '../../styles/dashboardTheme'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import './App.css'
import { Loading } from './Loading'

function App() {
  return (
    <ThemeProvider theme={dashboardTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <>
          <Loading />
          <ToastContainer
            position='bottom-left'
            autoClose={5000}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
          <Navbar />
          <Stack direction='row' justifyContent='flex-start'>
            <Sidebar />
            <MyRoutes />
          </Stack>
        </>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
