import { Box } from '@mui/material';
import Back from '../../assets/images/back.png';

function Sidebar() {
  return (
    <Box
      bgcolor='skyblue'
      sx={{
        display: { xs: 'none', lg: 'block' },
        width: '560px',
        // height: '100vh',
        minHeight: '100vh',
        backgroundImage: `url(${Back})`,
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}></Box>
  );
}

export default Sidebar;
