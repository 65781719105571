import { Box, Button, Stack, styled } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
// import ShowMoreText from 'react-show-more-text';
import { usePurchasedeal } from '../hooks/usePurchasedeal';
import { GrataExclusive, HiddenText, OptInButton, RetailPrice } from './styles';

export type DealsCartdProps = {
  loading: boolean,
  data: {
    id: number,
    category_id: number,
    category: string,
    grata_price: number,
    media: string,
    title: string,
    retail_price: number,
    price_unit: string,
    content: string,
  },
};

function DealsCard({ loading, data }: DealsCartdProps) {
  const purchaseDeal = usePurchasedeal();
  const [showMore, setShowMore] = useState(false);
  // const matches = useMediaQuery('(min-width:600px)');
  // const bigScreen = useMediaQuery('(min-width:1200px)');

  const retailPrice = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data.price_unit,
  }).format(data.retail_price);

  const optinPrice = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: data.price_unit,
  }).format(data.grata_price);

  return (
    <Card
      raised={true}
      sx={{
        maxWidth: { xs: '100%', sm: 750 },
        borderRadius: 3,
        padding: 1,
        margin: '10px 0px',
        backgroundColor: { xs: 'rgba(255, 255, 255, 0.95)', sm: 'white' },
      }}>
      <Stack
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            width: { xs: '100%', sm: 100 },
          }}>
          <CardMedia
            component='img'
            height='194'
            image={data.media}
            alt='Paella dish'
            sx={{ height: 70, width: 70, borderRadius: 2 }}
          />

          <Box
            sx={{
              display: { xs: 'flex', sm: 'none' },
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              width: 200,
            }}>
            <OptInButton
              variant='contained'
              onClick={() =>
                purchaseDeal(
                  {
                    dealId: data.id,
                    price: data.grata_price,
                    priceUnit: data.price_unit,
                  },
                  // data.price_unit,
                )
              }
              disabled={loading}>
              {loading ? 'Loading ...' : `Opt In for ${optinPrice}/month`}
            </OptInButton>

            <RetailPrice>{`Retail Price: ${retailPrice}/month`}</RetailPrice>
          </Box>
        </Box>

        <CardContent
          sx={{
            width: { xs: '100%', sm: '100%' },
            paddingLeft: '15px',
            paddingTop: 0,
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Typography variant='h6'>{data.title}</Typography>
          <GrataExclusive>{data.category}</GrataExclusive>
          <Typography variant='body2' component='span'>
            {
              data.content.length < 140 ? (
                <Typography>{data.content}</Typography>
              ) : showMore === true ? (
                <Typography>{data.content}</Typography>
              ) : (
                <HiddenText>{data.content}</HiddenText>
              )

              // `${data.content.substring(0, 140)}...`
            }
            {data.content.length >= 140 && (
              <Typography
                sx={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' }}
                variant='caption'
                color='primary'
                component='span'
                onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Show less' : 'Show more'}
              </Typography>
            )}
          </Typography>
        </CardContent>

        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            width: { xs: '100%', sm: 350 },
          }}>
          <OptInButton
            variant='contained'
            onClick={() =>
              purchaseDeal({
                dealId: data.id,
                price: data.grata_price,
                priceUnit: data.price_unit,
              })
            }
            disabled={loading}>
            {loading ? 'Loading ...' : `Opt In for ${optinPrice}/month`}
          </OptInButton>
          <RetailPrice>{`Retail Price: ${retailPrice}/month`}</RetailPrice>
        </Box>
      </Stack>
    </Card>
  );
}

export default DealsCard;
