import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, Grid, Link } from '@mui/material';

function Cancel() {
  return (
    <Container
      sx={{
        paddingTop: 1,
        flex: 1,
      }}>
      <h1>Cancelled</h1>
      <h2>Your payment was cancelled</h2>
      <Link href='/'>
        <Grid container direction='row' alignItems='center'>
          <Grid item>
            <ArrowBackIcon color='primary' />
          </Grid>
          <Grid item>Back to deals</Grid>
        </Grid>
      </Link>
    </Container>
  );
}

export default Cancel;
