import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Deals from '../pages/Deals'
import Cancel from '../pages/Deals/Cancel'
import Success from '../pages/Deals/Success'
import Notfound from '../pages/Notfound'

function MyRoutes() {
  return (
    <Routes>
      <Route path='/:building' element={<Deals />} />
      <Route path='success' element={<Success />} />
      <Route path='cancel' element={<Cancel />} />
      <Route path='*' element={<Deals />} />
    </Routes>
  )
}

export default MyRoutes
