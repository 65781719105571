import { AppBar, Box, styled, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  color: 'black',
});

function Navbar() {
  return (
    <AppBar position='sticky'>
      <StyledToolbar>
        <Typography variant='h6'>
          <Link to='/'>
            <Box
              component='img'
              sx={{
                height: 35,
              }}
              alt='Grata deals.'
              src={Logo}
            />
          </Link>
        </Typography>
      </StyledToolbar>
    </AppBar>
  );
}

export default Navbar;
