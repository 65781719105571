// import { createStandaloneToast } from '@chakra-ui/react';
import { QueryClient } from 'react-query';
import { toast } from 'material-react-toastify';

function queryErrorHandler(error: unknown): void {
  const title = error instanceof Error ? error.message : 'error connecting to server';

  // If token has expired, reload page to refresh token
  if (title === 'Request failed with status code 403') {
    location.reload();
  }
  // prevent duplicate toasts
  toast.dismiss();
  toast.error(title);
}

export const generateQueryClient = (): QueryClient => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        onError: queryErrorHandler,
        staleTime: 600000, // 10 minutes
        cacheTime: 900000, // 15 minutes
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
      },
      mutations: {
        onError: queryErrorHandler,
      },
    },
  });
};

export const queryClient = generateQueryClient();
