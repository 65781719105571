import { useQuery } from 'react-query';
import { getStoredUser, setStoredUser } from 'user-storage';
import { useGetCurrentUser } from '../../../helper/hooks/useGetCurrentUser';
import { queryKeys } from '../../../react-query/constants';
import { queryClient } from '../../../react-query/queryClient';
import { User } from '../../../types/types';
interface UseUser {
  user: User | null;
  updateUser: (user: User) => void;
  clearUser: () => void;
}

export function useUser(): UseUser {
  const { getCurrentUser } = useGetCurrentUser();

  const { data: user } = useQuery(queryKeys.user, getCurrentUser, {
    initialData: getStoredUser,
    onSuccess: (received: null | User) => {
      if (!received) {
        // clearStoredUser()
      } else {
        setStoredUser(received);
      }
    },
  });

  // meant to be called from useAuth
  function updateUser(newUser: User): void {
    queryClient.setQueryData(queryKeys.user, newUser);
  }

  function clearUser() {
    queryClient.setQueryData(queryKeys.user, null);

    // queryClient.removeQueries(queryKeys.deals)
  }

  return { user, updateUser, clearUser };
}
