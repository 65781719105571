import { Box, Button, styled, Typography } from '@mui/material';

export const GrataExclusive = styled(Box)({
  backgroundColor: 'green',
  fontSize: '10px',
  width: 'fit-content',
  padding: '1px 10px',
  color: 'white',
  borderRadius: 5,
});

export const RetailPrice = styled(Box)({
  fontSize: 'small',
  width: 'fit-content',
  padding: '1px 10px',
  color: 'red',
  borderRadius: 5,
  textDecoration: 'line-through',
});

export const OptInButton = styled(Button)(({ theme }) => ({
  color: 'white',
  width: 190,
  padding: '5px 5px',
  fontSize: 'small',
  '&:hover': {
    background: theme.palette.primary.light,
  },

  fontWeight: 600,
  borderRadius: 8.5,
  textTransform: 'none',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '&.Mui-disabled': {
    color: 'white',
    background: theme.palette.primary.light,
  },
}));

export const HiddenText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  lineHeight: '1.5rem',
  maxHeight: '4.5rem',
});
