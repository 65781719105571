export interface User {
  org_id: number;
  building_id: number;
  aud: string;
  iat: string;
  exp: string;
  jwt: string;
}

export interface Deal {
  active: boolean;
  building_id: number;
  category: string;
  category_id: number;
  content: string;
  grata_price: number;
  id: number;
  is_public: boolean;
  media: string;
  offered_by: string;
  price_unit: string;
  purchased: boolean;
  retail_price: number;
  title: string;
}
export interface Purchase {
  dealId: number;
  price: number;
  priceUnit: string;
}

export enum PRODUCT_TYPE {
  OFFERS = 1,
}
