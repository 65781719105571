import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Back from '../../assets/images/back.png';
import DealsCard from './DealsCard';
import { useAuth } from '../../auth/useAuth';
import { useDeals } from './hooks/useDeals';
import { Deal } from 'types/types';

function Deals() {
  const location = useLocation();
  const deals = useDeals();
  const { signin } = useAuth();

  useEffect(() => {
    signin();
  }, [location]);

  return (
    <>
      <Container
        sx={{
          paddingTop: 1,
          flex: 1,

          backgroundImage: { xs: `url(${Back})`, sm: 'none' },
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'contain',
        }}>
        {deals?.length > 0 && deals.map((item: Deal) => <DealsCard key={item.id} loading={false} data={item} />)}
      </Container>
    </>
  );
}

export default Deals;
