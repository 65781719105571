import { User } from 'types/types';
import { useGetCurrentUser } from '../helper/hooks/useGetCurrentUser';
import { useUser } from '../pages/User/hooks/useUser';

export function useAuth() {
  const { updateUser } = useUser();
  const { getCurrentUser } = useGetCurrentUser();

  // Get base64 encoded url and decode it to get object with org_id and building_id
  function signin() {
    loginUser(getCurrentUser());
  }

  function loginUser(user: User) {
    updateUser(user);
  }

  // Return the user object and auth methods
  return { signin, getCurrentUser };
}
