import { User } from '../types/types';

const USER_LOCALSTORAGE_KEY = 'grata-deals';

// helper to get user from localstorage
export function getStoredUser(): User {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY);

  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user: User) {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
}

export function clearStoredUser() {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
}
