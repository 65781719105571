import { Container, Grid, Link } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

function Success() {
  return (
    <Container
      sx={{
        paddingTop: 1,
        flex: 1,
      }}>
      <h1>Thank you!</h1>
      <h2>
        The payment has been processed, we will get back to you on next steps
        for your opted in deal within 24 hours.
      </h2>
      <Link href='/'>
        <Grid container direction='row' alignItems='center'>
          <Grid item>
            <ArrowBackIcon color='primary' />
          </Grid>
          <Grid item>Back to deals</Grid>
        </Grid>
      </Link>
    </Container>
  )
}
export default Success
