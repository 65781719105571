import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useUser } from '../pages/User/hooks/useUser';
import { baseUrl } from './constants';

const config: AxiosRequestConfig = { baseURL: baseUrl };
export const axiosInstance: AxiosInstance = axios.create(config);

const useAxios = () => {
  const { user } = useUser();

  function getJWTHeader() {
    return `Bearer ${user.jwt}`;
  }

  const request = ({ ...options }) => {
    const onSuccess = (response) => response;
    const onError = (error: unknown) => {
      throw error;
    };

    if (user?.jwt) {
      const auth = getJWTHeader();
      axiosInstance.defaults.headers.common['Authorization'] = auth;

      return axiosInstance(options).then(onSuccess).catch(onError);
    }

    return axiosInstance(options).then(onSuccess).catch(onError);
  };

  return { request, axiosInstance };
};

export default useAxios;
