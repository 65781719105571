import { useQuery } from 'react-query';
import { Deal } from 'types/types';
import useAxios from '../../../axiosInstance';
import { queryKeys } from '../../../react-query/constants';
import { useUser } from '../../User/hooks/useUser';

export function useDeals(): Deal[] {
  const { user } = useUser();
  const { request } = useAxios();

  async function getDeals(): Promise<Deal[]> {
    const res = await request({
      url: '/public/offers',
      method: 'get',
    });
    return res?.data;
  }

  const fallback: Deal[] = [];
  const { data = fallback } = useQuery([queryKeys.deals, queryKeys.user], getDeals, {
    // The query will not execute until the userId exists
    enabled: !!user?.jwt,
  });

  return data;
}
