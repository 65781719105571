import { createTheme } from '@mui/material/styles';
// import { Theme } from '@mui/material/styles'

export let dashboardTheme = createTheme({
  palette: {
    background: {
      default: 'white',
    },
    primary: {
      main: '#2B4F7C',
      light: '#0287E8',
    },
    secondary: {
      main: '#8B8B8B',
    },
    warning: {
      main: '#F47C14',
    },
    error: {
      main: '#E70023',
    },
    success: {
      main: '#00804C',
    },

    // neutral: {
    //   main: '#64748B',
    //   contrastText: '#fff',
    // },
    // positive: {
    //   main: '#00A063',
    // },
  },
});

dashboardTheme = createTheme(dashboardTheme, {
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            fill: 'rgba(26, 132, 238, 0.05);',
          },
          '&.Mui-completed': {
            fill: '#72E1D1',
          },
          color: '#ECEFF2',
        },
        text: {
          fill: '#2B4F7C',
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px 4px 0px 0px',
          '&:before': {
            borderBottom: 'none',
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#72E1D1',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        iconSeparator: {
          display: 'none',
        },
        columnHeaderTitle: {
          color: '#8094A7',
          fontSize: '12px',
          lineHeight: '24px',
          fontWeight: '400px',
        },
        columnHeaders: {
          // background: dashboardTheme.palette.primary.main,
        },
        root: {
          // '& div[data-rowindex][role="row"]:nth-of-type(2n+1)': {
          //   backgroundColor: 'rgba(128,128,128,0.4)',
          // },
          '& .MuiDataGrid-cell:hover': {
            color: 'blue',
            // background: '#FCFCFC',
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-stickyHeader': {
            background: dashboardTheme.palette.primary.main,
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& tr:nth-of-type(2n+1)': {
            backgroundColor: 'rgba(128,128,128,0.4)',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(128,128,128,0.4)',
          width: 'max-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 4,
          borderRadius: 2,
          maxHeight: 500,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 600,
          borderRadius: 8.5,
          textTransform: 'none',
          '&.MuiButton-contained': {
            backgroundColor: dashboardTheme.palette.primary.main,
            '&:hover': {
              backgroundColor: dashboardTheme.palette.primary.light,
            },
          },
          // '&.MuiButton-outlined': {
          //   color: '#fff',
          //   borderColor: 'rgba(255, 255, 255, 0.7)',
          //   '&:hover': {
          //     backgroundColor: 'rgba(0, 0, 0, 0.04)',
          //   },
          // },
          '&.Mui-disabled': {
            background: dashboardTheme.palette.primary.light,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.7rem',
        },
      },
    },
  },

  typography: {
    h1: {
      fontSize: '1.6rem',
      fontWeight: 600,
      // color: '#fff',
      letterSpacing: '0.5px',
      textTransform: 'capitalize',
    },
  },
});

// declare module '@mui/material/styles' {
//   interface Theme {
//     status: {
//       danger: React.CSSProperties['color']
//     }
//   }

//   interface CustomPalette {
//     neutral: {
//       main: string
//       contrastText: string
//     }
//   }

//   interface Palette extends CustomPalette {}

//   interface PaletteOptions extends CustomPalette {}

//   interface PaletteColor {
//     darker?: string
//   }
//   interface SimplePaletteColorOptions {
//     darker?: string
//   }
//   interface ThemeOptions {
//     status: {
//       danger: React.CSSProperties['color']
//     }
//   }
// }

// // Update the Button's color prop options
// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     neutral: true
//   }
// }
// it could be your App.tsx file or theme file that is included in your tsconfig.json

// declare module '@mui/styles/DefaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface (remove this line if you don't have the rule enabled)
//   interface DefaultTheme extends Theme {}
// }
