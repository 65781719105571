import { useLocation } from 'react-router-dom';
import Buffer from 'buffer';
import sign from 'jwt-encode';
import moment from 'moment';
import { User } from '../../types/types';
import { getStoredUser } from 'user-storage';

export function useGetCurrentUser() {
  const location = useLocation();

  const getCurrentUser = (): User | undefined => {
    const myBuilding = location.pathname.substring(1);
    const buff = new Buffer.Buffer(myBuilding, 'base64');
    const text = buff.toString('ascii');
    let decoded;
    try {
      decoded = JSON.parse(text);
    } catch (error) {
      return getStoredUser();
    }

    const orgId = decoded?.org_id;
    const buildingId = decoded?.building_id;

    // with org_id and building_id create the JWT for this user

    let user = null;
    if (orgId && buildingId) {
      const userObject = {
        org_id: orgId,
        building_id: buildingId,
        aud: 'grata',
        iat: moment().format('x'),
        exp: moment().add(1, 'years').format('x'),
      };
      const jwt = sign(userObject, process.env.REACT_APP_JWT_SECRET);
      user = { ...userObject, jwt };
      return user;
    }

    return getStoredUser();
  };

  return { getCurrentUser };
}
